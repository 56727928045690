<template>
  <div class="payIndex">
    <div>
      <a-button @click="getPay('1')">
        线上支付
      </a-button>
      <a-button @click="getPay('2')">
        线下支付
      </a-button>
    </div>
    <div class="tit">
      应付保费总计<span style="color:red;margin-left:20px">506.27 元</span>
    </div>
    <div
      class="btn"
      v-if="isPay==1"
    >
      <a-button type="primary">
        去支付
      </a-button>
    </div>
    <div
      class="pay"
      v-if="isPay==2"
    >
      <div>请将保费转账至下列账户并提交转账凭证</div>
      <div class="pay-form">
        <a-form
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-item label="转入银行">
            <div>上海银行</div>
          </a-form-item>
          <a-form-item label="户名">
            <div>四川金鼎担保有限公司</div>
          </a-form-item>
          <a-form-item label="账号">
            <div>666666666666666666666</div>
          </a-form-item>
          <a-form-item label="上传转账凭证">
            <a-upload
              name="file"
              :multiple="true"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              :headers="headers"
              @change="handleChange"
            >
              <a-button>点击上传</a-button>
            </a-upload>
          </a-form-item>
          <a-form-item>
            <a-button type="primary">
              支付完成
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      headers: {
        authorization: 'authorization-text',
      },
      isPay: 1,
    };
  },
  methods: {
    handleChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    getPay(num) {
      this.isPay = num;
    },
  },
};
</script>
<style lang="less" scoped>
    .payIndex{
        padding: 100px 200px;
    }
    .tit{
        font-size: 24px;
        font-weight: 800;
        margin: 50px 70px;
    }
    .btn{
        margin: 100px 200px 120px;
    }
    .pay{
        margin: 50px 70px;
        .pay-form{
            margin-top: 30px;
            margin-left: 60px;
        }
    }
    ::v-deep .ant-form-item-control{
        .ant-btn-primary{
            margin-left: 20%;
        }
    }
    ::v-deep .ant-form-item-label{
        text-align: left;
    }
</style>
